







































































































































































import Vue from "vue";
import { generate_random_key } from "@/utils/global";
import { ViewJobState } from "@/store/modules/common/interfaces";
import { mapGetters } from "vuex";
import { VIEW_JOB_STATE } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "CriticalWorkFunctions",
  data() {
    return {
      objective_of_role_allowed_states: [
        ViewJobState.JOB_LISTING,
        ViewJobState.STANDARDISED,
        ViewJobState.STANDARDISED_JOB_POST,
        ViewJobState.LINKEDIN_JOB_POST
      ],
      responsibilities_allowed_states: [
        ViewJobState.JOB_LISTING,
        ViewJobState.STANDARDISED,
        ViewJobState.STANDARDISED_JOB_POST,
        ViewJobState.LINKEDIN_JOB_POST
      ],
      analyze_business_requirements_allowed_states: [
        ViewJobState.STANDARDISED,
        ViewJobState.STANDARDISED_JOB_POST
      ]
    };
  },
  computed: {
    ...mapGetters("common", {
      view_job_state: VIEW_JOB_STATE
    })
  },
  methods: { generate_random_key }
});
